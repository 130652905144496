.Sending {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  z-index: 999;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 64px;
}
.Sending p {
  animation: textTwinkle 1.5s infinite;
}
@keyframes textTwinkle {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
