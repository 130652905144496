ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  color: black;
  text-decoration: none;
}

.Header {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
}
#gnb {
  display: flex;
  height: 150px;
}
.H_logo {
  left: 0;
  top: 10px;
  width: 200px;
  height: 68px;
  margin-left: 0;
  
  
}
.H_logo img {
  display: block;
  width: 160px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.gnb_nav {
  position: absolute;
  display: flex;
  top: 6px;
  right: 0;
}
.gnb_nav ul {
  display: flex;
  margin: 0 auto;
}
.gnb_nav .dep1 {
  display: block;
  margin-left: 50px;
}

.gnb_nav .dep1 span {
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 3;
}

.dep1 li {
  line-height: 1.6;
}

.gnb_nav .dep1 span:hover {
  color: #000;
  cursor: pointer;
  transition: all 0.3s;
}
.gnb_nav .dep1 span:before {
  width: 100%;
  border-bottom: 3px solid #000;
}

.dep1 span:after {
  content: "";
  width: 0%;
  height: 100%;
  top: -10px;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  border-bottom: 3px solid #000;
  z-index: 100;
}

.dep1 span:hover:after {
  width: 100%;
  z-index: +1;
}

.dep1 a:hover {
  color: #000;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.dropdown_menu {
  display: none;
}
.dropdown_menu li:hover {
  color: #000;
  cursor: pointer;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.dropdown_menu li {
  opacity: 1;
}

.gnb_nav .dep1:hover .dropdown_menu li {
  opacity: 1;
  transition: all 0.5s;
}

/* 햄버거 */
.menu-trigger {
  margin-right: 70px;
  margin-bottom: 50px;
}
.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
}

.menu-trigger {
  display: none;
  position: absolute;
  top: 5px;
  right: -52px;
  width: 45px;
  height: 30px;
  z-index: 1000;
}

.menu-trigger span {
  width: 100%;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
}

.menu-trigger span:nth-of-type(1) {
  top: 0;
}

.menu-trigger span:nth-of-type(2) {
  top: 20px;
}

.menu-trigger span:nth-of-type(3) {
  bottom: 0px;
}


/* sub_nav */

.sub_nav{
  margin-top: 2px;
  margin-left: 35px;
}

.dep_Language{
  margin-top: 15px;
}

/* 회전 */
/* .menu-trigger.active-1 span:nth-of-type(1) {
    -webkit-transform: translateY (20px) rotate (-45deg);
    transform: translateY(20px) rotate(-45deg);
  }
  
  .menu-trigger.active-1 span:nth-of-type(2) {
    opacity: 0;
  }
  
  .menu-trigger.active-1 span:nth-of-type(3) {
    -webkit-transform: translateY(-20px) rotate(45deg);
    transform: translateY(-20px) rotate(45deg);
  } */
@media (max-width: 1200px) {
    .Header {
        height: 70px;
        display: flex;
    }
  .H_logo {
    margin-left: 3vw;
    margin-top: 2px;
  }
  .gnb_nav {
    margin-top:2px;
  }
}
@media (max-width: 1100px) {
  .H_logo img {
    margin-top: 2px;
    width: 150px;
  }
  .gnb_nav {
    position: absolute;
    right: 5vw;
    top: 0;
    margin-top: 8.5px;
    margin-left: 0;
  }
  .gnb_nav .dep1 {
    margin-left: 30px;
  }
  .gnb_nav .dep1 span {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
    .Header {
        margin-bottom: 5px;
        margin-top: 10px;
    }
  .gnb_nav ul {
    position: absolute;
    display: none;
    width: 100%;
    height: auto;
    top: -30px;
    padding-top: 120px;
    padding-bottom: 30px;
    margin: 0 auto;

    background-color: rgba(144, 144, 144, 0.9);
  }
  .gnb_nav {
    display: block;
    width: 100%;
    height: auto;
    top: 0;

    margin: 0;
    left: 0;
    z-index: 100;
  }
  .gnb_nav .dep1 {
    margin: 0;
  }
  /* 햄버거 */
  .menu-trigger {
    display: block;
    
  }
  .sidebar-button img{
    margin-top: -10px;
  }
}
