.Support{
  width: 100%;
  margin: 0 auto;
}
.Support_bg {
  max-width: 1200px;
  margin: 0 auto;
}
.Support_bg p {
  font-size: 0;
}
.Support_bg p img{
  display: block;
  width: 100%;
}
.Support_wrap{
  max-width: 1000px;
  margin: 0 auto;
}


/* Vehicle_Compatibility_Table */
.Vehicle_Compatibility_Table h3{
  color: #000;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.9px;
}
.Vehicle_Compatibility_Table p {
  font-size: 0;
}
.Vehicle_Compatibility_Table p img{
  display: block;
  width: 100%;
}

hr{
  width: 100%;
  height: 1;
  background-color: #000;
  border:0;
  margin-top: 23px;
  margin-bottom: 40px;
}

/* How_To_Replacefilter */
.How_To_Replacefilter{
  max-width: 1000px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 40px;
}

.How_To_Replacefilter_text h3{
  color: #000;
  font-family: Pretendard;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: -0.9px;
}
.How_To_Replacefilter_text h4{
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  letter-spacing: -0.6px;
}
.How_To_Replacefilter_text h4 span{
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: -0.6px;
}
.hr{
  width: 44%;
  background: #00CDA8;
  margin: 0;
  border: 0;
}
.hr2{
  margin-top: 20px;
  margin-bottom: 22px;
}
.hr3{
  margin-top: 30px;
  margin-bottom: 17px;
}

.How_To_Replacefilter_text ul li{
  color: #00CDA8;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px; /* 187.5% */
  letter-spacing: -0.48px;
}
.How_To_Replacefilter_text h5{
  color: #8D8D8D;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 162.5% */
  letter-spacing: -0.48px;
}
.How_To_Replacefilter_text p{
  color: #8D8D8D;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
  letter-spacing: -0.48px;
}

/* How_To_Replacefilter_img */
.How_To_Replacefilter_img{
  width: 100%;
}
.How_To_Replacefilter_img{
  max-width: 49.8%;
  font-size: 0;
  margin-top: 38px;
}
.How_To_Replacefilter_img p img{
  display: block;
  width: 100%;
}


@media  (max-width: 1100px){
  .Support_wrap{
    width: 90%;
  }
  .How_To_Replacefilter{
    display: block;
  }
  .How_To_Replacefilter_text , .How_To_Replacefilter_img{
    display: block;
    width: 90%;
    margin: 0 auto;
    
  }
  .How_To_Replacefilter_text p{
    width: 90%;
      
  }
  .How_To_Replacefilter_img p img{
    margin-top: 20px;
  }
  .How_To_Replacefilter_img{
    max-width: 52%;
  }
}

@media  (max-width: 800px){
  .How_To_Replacefilter_img {
      max-width: 90%;
      margin: 0 auto;
  }

}